import { useState, createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const [token, setToken] = useState(Cookies.get("token") || null);
  const [access, setAccess] = useState(Cookies.get("access") || null);
  const [role, setRole] = useState(Cookies.get("role") || null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = (data) => {
    setToken(data.accessToken);
    Cookies.set("token",data.accessToken)
    Cookies.set("refreshToken",data.refreshToken);
    if(!data.roles.includes('admin')){
      const axis = data.permissions.length>0 ? [...data.permissions,'profile'] : ['profile'];
      setAccess(axis);
      setRole("user");
      Cookies.set("access",axis)
      Cookies.set("role","user");
    }else{
      setAccess([]);
      setRole("admin");
      Cookies.set("access",[])
      Cookies.set("role","admin");
    }

    navigate("/dashboard");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setToken(null);
    setAccess(null);
    setRole(null);
    Cookies.remove("access")
    Cookies.remove("role")
    Cookies.remove("token")
    Cookies.remove("refreshToken")
    navigate("/", { replace: true });
  };


  const value = useMemo(
    () => ({
      token,
      access,
      role,
      login,
      logout,
    }),
    [token]
  );
  return <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export const useAuth = () => {
  return useContext(AuthContext);
};