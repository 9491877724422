import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const ProtectedLayout = Loadable(lazy(() => import('../layouts/ProtectedLayout')));
const GlobalLayout = Loadable(lazy(() => import('../layouts/GlobalLayout')));
/***** Pages ****/
/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../pages/Error')));
const LoginForm = Loadable(lazy(() => import('../pages/auth/loginForm')));

/*****Routes******/

const Dashboard = Loadable(lazy(() => import('../pages/common/dashboard')));
const Cities = Loadable(lazy(() => import('../pages/common/city')));
const Tags = Loadable(lazy(() => import('../pages/common/tags')));
const Categories = Loadable(lazy(() => import('../pages/common/category')));
const Actors = Loadable(lazy(() => import('../pages/common/actor')));

const AddSlider = Loadable(lazy(() => import('../pages/common/slider/add')));
const Sliders = Loadable(lazy(() => import('../pages/common/slider/index')));
const EditSlider = Loadable(lazy(() => import('../pages/common/slider/edit')));

const AddPlace = Loadable(lazy(() => import('../pages/common/place/add')));
const Places = Loadable(lazy(() => import('../pages/common/place/index')));
const EditPlace = Loadable(lazy(() => import('../pages/common/place/edit')));

const AddHall = Loadable(lazy(() => import('../pages/common/hall/add')));
const Halles = Loadable(lazy(() => import('../pages/common/hall/index')));
const EditHall = Loadable(lazy(() => import('../pages/common/hall/edit')));

const AddEvent = Loadable(lazy(() => import('../pages/common/event/add')));
const Events = Loadable(lazy(() => import('../pages/common/event/index')));
const EditEvent = Loadable(lazy(() => import('../pages/common/event/edit')));

const Sans = Loadable(lazy(() => import('../pages/common/sans/index')));
const AddSans = Loadable(lazy(() => import('../pages/common/sans/add')));
const EditSans = Loadable(lazy(() => import('../pages/common/sans/edit')));
const ReportSans = Loadable(lazy(() => import('../pages/common/sans/report')));

const Advertisement = Loadable(lazy(() => import('../pages/common/ad/index')));
const AddAdvertisement = Loadable(lazy(() => import('../pages/common/ad/add')));
const EditAdvertisement = Loadable(lazy(() => import('../pages/common/ad/edit')));

const Users = Loadable(lazy(() => import('../pages/common/user/index')));
const Producers = Loadable(lazy(() => import('../pages/common/user/producer')));
const UserInfo = Loadable(lazy(() => import('../pages/common/user/show')));

const Permission = Loadable(lazy(() => import('../pages/common/user/permission')));

const ReportOrders = Loadable(lazy(() => import('../pages/common/report/order')));
const ReportOrdersSummery = Loadable(lazy(() => import('../pages/common/report/orderSummery')));
const ReportOrdersTotal = Loadable(lazy(() => import('../pages/common/report/orderTotal')));
const ReportFinancial = Loadable(lazy(() => import('../pages/common/report/financial')));

const Supports = Loadable(lazy(() => import('../pages/common/support/index')));
const NewSupport = Loadable(lazy(() => import('../pages/common/support/add')));
const Support = Loadable(lazy(() => import('../pages/common/support/show')));

const Fee = Loadable(lazy(() => import('../pages/common/fee')));
const Settings = Loadable(lazy(() => import('../pages/common/settings')));
const Profile = Loadable(lazy(() => import('../pages/common/profile')));
const Promotion = Loadable(lazy(() => import('../pages/common/promotion')));

const Factors = Loadable(lazy(() => import('../pages/common/factors/index')));
const FactorsCreate = Loadable(lazy(() => import('../pages/common/factors/create')));
const FactorsShow = Loadable(lazy(() => import('../pages/common/factors/show')));
const FactorPrev = Loadable(lazy(() => import('../pages/common/factors/prev')));

const Comment = Loadable(lazy(() => import('../pages/common/comment')));




const ThemeRoutes = [
  {
    path: '/',
    element: <ProtectedLayout />,
    children: [
      { path: '/', name: 'داشبورد', exact: true, element: <Dashboard />},
      { path: '/tag', name: 'برچسب', exact: true, element: <Tags />},
      { path: '/category', name: 'دسته بندی', exact: true, element: <Categories /> },
      { path: '/city', name: 'شهر', exact: true, element: <Cities /> },
      { path: '/actor', name: 'هنرمند', exact: true, element: <Actors /> },

      { path: '/slider', name: 'اسلایدر‌', exact: true, element: <Sliders /> },
      { path: '/slider/add', name: 'افزودن اسلایدر', exact: true, element: <AddSlider /> },
      { path: '/slider/:id', name: 'ویرایش اسلایدر', exact: true, element: <EditSlider /> },
      
      { path: '/place', name: 'محل برگزاری‌ها', exact: true, element: <Places /> },
      { path: '/place/add', name: 'افزودن محل برگزاری', exact: true, element: <AddPlace /> },
      { path: '/place/:id', name: 'ویرایش محل برگزاری', exact: true, element: <EditPlace /> },

      { path: '/hall', name: 'سالن‌ها', exact: true, element: <Halles /> },
      { path: '/hall/add', name: 'افزودن سالن', exact: true, element: <AddHall /> },
      { path: '/hall/:id', name: 'ویرایش سالن', exact: true, element: <EditHall /> },


      { path: '/event', name: 'برنامه‌ها', exact: true, element: <Events /> },
      { path: '/event/add', name: 'افزودن برنامه', exact: true, element: <AddEvent /> },
      { path: '/event/:id', name: 'ویرایش برنامه', exact: true, element: <EditEvent /> },

      
      { path: '/sans', name: 'سانس‌ها', exact: true, element: <Sans /> },
      { path: '/sans/add', name: 'افزودن سانس', exact: true, element: <AddSans /> },
      { path: '/sans/report/:id', name: 'سانس‌ها', exact: true, element: <ReportSans /> },
      { path: '/sans/:id', name: 'ویرایش سانس', exact: true, element: <EditSans /> },

      { path: '/factor', name: 'فاکتور', exact: true, element: <Factors /> },
      { path: '/factor/create', name: 'فاکتور', exact: true, element: <FactorsCreate /> },
      { path: '/factor/:id', name: 'پیش نمایش فاکتور', exact: true, element: <FactorPrev /> },
      { path: '/factors/:id', name: 'نمایش فاکتور', exact: true, element: <FactorsShow /> },
      
      { path: '/advertising', name: 'تبلیغ‌‌ها', exact: true, element: <Advertisement /> },
      { path: '/advertising/add', name: 'افزودن تبلیغ', exact: true, element: <AddAdvertisement /> },
      { path: '/advertising/:id', name: 'ویرایش تبلیغ', exact: true, element: <EditAdvertisement /> },

      { path: '/user', name: 'کاربران', exact: true, element: <Users /> },
      { path: '/user/producers', name: 'تامین کنندگان', exact: true, element: <Producers /> },
      { path: '/user/:id', name: 'نمایش اطلاعات', exact: true, element: <UserInfo /> },
      
      { path: '/fee', name: 'کارمزدها', exact: true, element: <Fee /> },
      { path: '/setting', name: 'تنظیمات', exact: true, element: <Settings /> },
      { path: '/profile', name: 'حساب کاربری', exact: true, element: <Profile /> },

      { path: '/order', name: 'سفارشات', exact: true, element: <ReportOrders /> },
      { path: '/order/summery', name: 'سفارشات', exact: true, element: <ReportOrdersSummery /> },
      { path: '/order/total', name: 'سفارشات', exact: true, element: <ReportOrdersTotal /> },
      { path: '/deposit', name: 'مالی', exact: true, element: <ReportFinancial /> },

      { path: '/supportTicket', name: 'برنامه‌ها', exact: true, element: <Supports /> },
      { path: '/supportTicket/new', name: 'افزودن برنامه', exact: true, element: <NewSupport /> },
      { path: '/supportTicket/:id', name: 'ویرایش برنامه', exact: true, element: <Support /> },

      { path: '/permission', name: 'مدیریت‌ها', exact: true, element: <Permission /> },
      { path: '/promotion', name: 'کد تخفیف', exact: true, element: <Promotion />},

      { path: '/comment', name: 'کامنت‌ها', exact: true, element: <Comment />},

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <GlobalLayout />,
    children: [
      { path: '', element: <Navigate to="/auth/login" /> },
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'login', element: <LoginForm /> },
    ],
  },
];

export default ThemeRoutes;
